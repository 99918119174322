import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/telegramBotSetting',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/telegramBotSetting',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/telegramBotSetting/inventory",
        method: "get",
        params
    });
}
export function show(id) {
    return request({
        url: `/telegramBotSetting/${id}`,
        method: 'get'
    })
}
export function update(data) {
    return request({
        url: `/telegramBotSetting/${parseInt(data.get('id'))}?_method=PUT`,
        method: 'post',
        data
    })
}
export function destroy(id) {
    return request({
        url: `/telegramBotSetting/${id}`,
        method: 'delete'
    })
}