export const ru_messages = {
    yes: 'Да',
    no: 'Нет',
    n: "№",
    total_amount: "Общая сумма",
    deals: "Сделки",
    deal: "Сделка",
    create: "Создать",
    create_board: "Создать доску",
    update_board: "Изменить доску",
    update: "Изменить",
    delete: "Удалить",
    board_name: "Имя доски",
    The_field_must_not_be_empty: "Поля не должно быть пустым",
    header_color: "Цвет заголовка",
    New_header_color: "Новый цвет заголовка",
    See_board_name: "Ввидите имя доски",
    New_board_name: "Новое имя доски",
    company: "Компания клиента",
    companies: "Компании клиентов",
    client: "Клиент",
    user: "Пользователь",
    name: "Наименование",
    first_name: "Имя",
    surname: "Фамилия",
    patronymic: "Отчество",
    phone: "Телефон",
    gender: "Пол",
    email: "Эл. адрес",
    role_id: "Роль",
    nameFull: "Имя",
    new_source: "Новый источник",
    save: "Сохранить",
    close: "Закрыть",
    search: "Поиск",
    source: "Источник",
    sources: "Источники",
    text: "Сообщение",
    text_t: "Текст",
    type_id: "Тип",
    settings: "Настройки",
    color: "Цвет",
    colors: "Цвета",
    date: "Дата",
    status: "Статус",
    count_product: "Количество",
    total_price: "Сумма",
    download: "Скачать",
    filial: "Компания пользователя",
    currency: "Валюта",
    currencies: "Валюты",
    begin_date: "Дата начала",
    end_date: "Дата окончания",
    without_finish_date: "Без даты окончания",
    deal_type: "Тип сделки",
    dealStage: "Стадия",
    comment: "Комментарий",
    dealType: "Тип сделки",
    created_at: "Дата создания",
    updated_at: "Дата изменения",
    symbol: "Символ",
    edit_m: "Изменить  {m}",
    new_m: "Новый  {m}",
    mfo: "МФО",
    okonx: "ОКОНХ",
    oked: "ОКЭД ",
    rkp_nds: "РКП НДС",
    work_phone: "Рабочий телефон",
    work_place: " Место работы",
    legal_address: "Юридический адрес",
    landmark: "Ориентир (Юридический адрес)",
    residence_place: "Адрес прописки",
    costType: 'Тип расхода',
    costTypes: 'Типы расходов',
    dealTypes: 'Тип сделки',
    measurements: 'Измерения',
    dealStages: "Стадия",
    // mi
    patronymic: 'Отчество',
    surname: 'Фамилия',
    pin_code: 'Пин код',
    is_employee: 'Сотрудник',
    //end
    nameGiven: "Название",
    nameFull: "Имя",
    requirements: "Требования",
    requirements_y: "Требования",
    reason: "Причина",
    reason_y: "Причины",
    priority: " Приоритет",
    priority_y: " Приоритеты",
    position: "Должность",
    position_p: "Должности",
    graphicType: "Тип графика",
    graphicType_p: "Типы графика",
    company: "Компания клиента",
    currency: "Валюта",
    currency_y: "Валюта",// не точно
    filial: "Компания пользователя",
    filter: "Фильтр",
    total_working_day: "Рабочая неделя",
    graphic: "График работы",
    language_graphic_type: "Пожалуйста, введите название",
    employmentType: "Вид занятости",
    province: "Город",
    region: "Город",
    region_: "Регион",
    vacancy: "Вакансии",
    last_name: "Фамилия",
    middle_name: "Отчество",
    date_of_birth: "Дата рождения",
    phone: "Телефон",
    email: "Эл. адрес",
    status: "Статус",
    role: "Роль",
    phone_number_y: "Телефон",
    summary: "Резюме",
    UploadFile: "Загрузить файл",
    UploadFileText: " файлы jpg/png размером менее 500 КБ ",
    serial_number: "Серийный номер",
    ip_address: "IP адрес",
    device: "Устройства",
    device_y: "Устройство",
    income: "Уровень дохода",
    work_type: "Статус сотрудника",
    district_id: "Район ",
    district: "Район",
    nation: "Нация",
    nation_y: "Нация",
    code: "Код",
    okurs: "Обратный курс",
    profile: "Профиль",
    dateAdoption: "Дата принятия",
    staffTest: "Испытательный ",
    operating: "Действующий",
    dismissed: "Уволенный",
    save: "Сохранить",
    save_and_exit: "Сохранить и закрыть",
    close: "Закрыть",
    candidates: "Кандидаты",
    find: "Поиск",
    daily: "Ежедневно",
    from: "От",
    before: "До",
    dinner: "Обед",
    users: "Пользователи",
    user: "Пользователь",
    user_id: "Пользователь",
    password: "Пароль",
    new_password: "Новый пароль",
    current_password: "Текущий пароль",
    confirm_password: "Повторить пароль",
    finance: "Финансы",
    money_amount: "Сумма",
    access_rights: "Права доступа",
    active: "Активный",
    is_active: "Активный",
    inactive: "Не активный",
    working_hours_report: "Отчёт по рабочему времени",
    age: "Возраст",
    nv_monitoring: "Мониторинг НВ",
    o_salary: "Оплата труда",
    new_incoming_payment: "Новый входящий платеж",
    incoming: "Входящий",
    outgoing: "Исходящий",
    employees: "Сотрудники",
    paymentTypes: "Тип оплаты",
    amount: "Сумма",
    check: "Счет",
    check_a: "Счета",
    salary_type: "Тип зарплаты",
    salary: "Зарплата",
    bank: "Банк",
    checking_account: "Расчетный счет",
    expiration_date: "Дата оканчания",
    comment: "Комментарий",
    overtime: "Сверхурочный",
    total: "Итого:",
    being_late: "Опоздания",
    other_penalties: "Другие штрафы",
    penalties: "Штрафы",
    bonuses: "Бонусы",
    on_hands: "На руки",
    passport_number: "Серия паспорта",
    passport_issued_by: "Кем выдан паспорт",
    passport_valid_until: "Срок действия паспорта",
    inn: "ИНН",
    job: "Работа",
    dashboard: "Дэшборд",
    hiring_date: "Дата найма",
    manager: "Руководитель",
    allow_th_employee_to_access_the_system: "Разрешить сотруднику доступ к системе.",
    add_candidates: "Добавить кандидатов",
    description: "Описание",
    photo: "Фото",
    min: "мини",
    max: "макс",
    recruiting: "Рекрутинг",
    desktop: "Рабочий стол",
    indicators: "Товарооборот",
    journal: "Журнал",
    quit: "Выйти",
    branch_name: "Название отдела",
    mfo: "МФО",
    number: "Номер",
    account: "Счет",
    card_number: "Номер карты",
    penalty_name: "Название штрафа",
    bonus_name: "Название бонуса",
    payment_date: "Дата платежа",
    adaptation: "Адаптация",
    agreement_employees: "Договора(сотрудники)",
    login: "Авторизоваться",
    permission: "Разрешения",
    synchronization: "Синхронизация",
    tests: "Тесты",
    wage: "Заработная плата",
    dafault: "По умолчанию",
    real_amount: "Реальная сумма",
    bonus_amount: "Бонусная сумма",
    payment_for: "Платеж за",
    code: "Код",
    measurement_id: "Измерение",
    measurement: "Измерение",
    buy_price: "Цена покупки",
    buy_price_currency_id: "Валюта покупки",
    selling_price: "Цена продажи",
    selling_price_currency_id: "Валюта продажи",
    filials: "Компании пользователей",
    deal_id: "Сделка",
    product_id: "Продукт",
    price: "Цена",
    currency_id: "Валюта",
    quantity: "Количество",
    discount: "Скидка",
    nds: "НДС",
    slug: "Слизняк",// нега керак
    file: "Файл",
    full_name: "Полное имя",
    company_id: "Компания клиента",
    date_of_birth: "Дата рождения",
    passport_serial_number: "Серийный номер паспорта",
    passport_validity: "Срок действия паспорта",
    actual_address: "Адрес",
    inn: "ИНН",
    gender_id: "Пол",
    type: "Тип",
    responsible: "Ответственный",
    country: "Страна",
    countries: "Страны",
    city: "Город",
    cities: "Города",
    city_id: "Город",
    country_id: "Страна",
    category: "Категория",
    category_id: "Категория",
    categories: "Категории",
    parent_id: "Родитель",
    product: "Продукт",
    products: "Продукты",
    weekly: 'Неделя',
    monthly: 'Месяц',
    yearly: 'Год',
    per: 'за',
    is_super_admin: 'Супер админ',
    new: "Новый",
    cancel: "Отмена",
    products: "Продукты",
    products: "Продукты",
    files: "Файлы",
    profit: "Прибыль",
    profit_in_percents: "Прибыль в процентах",
    profit_in_money: "Прибыль в деньгах",
    archivedDeals: "Архивированные сделки",
    deletedDeals: "Удаленные сделки",
    roles: "Роли",
    permissions: "Права доступа",
    show: "Показать",
    unzip: "Разархивировать",
    restore: "Восстановить",
    rate: "Курс",
    new_deal: "Новая сделка",
    new_task: "Новая задача",
    new_contact: "Новый контакт",
    no_data_yet: "Пока нет данных",
    reasons: "Причины",
    deal_reasons: "Причины сделки",
    reason: "Причина",
    clientType: "Тип клиента",
    clientTypes: "Типы клиентов",
    client_with_search: "Введите тип клиента для поиска",
    sale_by_period: "Продажа по периоду",
    finish_date: "Фактическая дата окончания",
    check_list_id: "Список",
    is_done: "Сделано",
    task_board_id: "Доска",
    board: "Доска",
    task_id: "Задача",
    task: "Задача",
    taskBig: "Все задачи",
    tasksMy: "Мои задачи",
    archivedTasks: "Архивированные задачи",
    deletedTasks: "Удаленные задачи",
    dealGoal: "Цель сделки",
    dealGoals: "Цели сделок",
    year: "Год",
    month: "Месяц",
    count: "Количество",
    by_count: "По количеству",
    by_money_amount: "По бюджету",
    by_profit: "По прибыли",
    color_id: "Цвет",
    dealCondition: "Условие оплаты",
    dealConditions: "Условия оплат",
    shipmentTerm: "Условие поставки",
    shipmentTerms: "Условия поставок",
    taskReason: "Причина задачи",
    taskReasons: "Причины задач",
    payment_in_percents: "Оплата в процентах",
    payment_in_money: "Оплата в деньгах",
    telegram_chat_id: "Идентификатор чата Telegram",
    package: "Упаковка",
    packages: "Упаковки",
    productSize: "Размер продукта",
    productSizes: "Размеры продукта",
    productModel: "Модель",
    productModels: "Модели",
    is_default: "По умолчанию",
    emails: "Эл. адресы",
    email: "Эл. адрес",
    Unseen: "Непрочитанный",
    Seen: "Прочитанный",
    Answered: "Отвечено",
    kpTemplate: "Шаблон КП",
    kpTemplates: "Шаблоны КП",
    header: "Верхний текст",
    footer: "Нижний текст",
    columns: "Столбцы",
    sip_number: "Внутренний номер",
    sip_password: "Пароль внутреннего номера",
    sipAccount: "Внутренний номер",
    sipAccounts: "Внутренние номера",
    name_title: "Обращение",
    phone_number: "Телефон",
    website: "Веб-сайт",
    contact_type: "Тип контакта",
    phoneContact: "Контакт",
    phoneContacts: "Контакты",
    reception_address: "Адрес приемной",
    control_user: "Пользовательский контроль",
    user_permission_type: "Тип пользовательского контроля",
    calendarNotifications: "Напоминания",
    calendarNotification: "Напоминания",
    title: "Наименование",
    server_url: "Адрес сервера",
    port: "Порт",
    extension_type: "Тип внутреннего номера",
    additional: "Дополнительно",
    spectators: "Наблюдатели",
    caller: "Исходящий номер",
    called_to: "Входящий номер",
    billsec: "Время",
    callStatus: "Статус",
    calldate: "Дата и время",
    recordingfile: "Запись",
    user_telegram_status: 'Статус телеграма',
    department_list: 'Отделы',
    department: 'Отдел',
    select_status: 'Выберите статус',
    available: 'Доступно',
    not_disturb: 'Не беспокоить',
    busy: 'Занят',
    absent: 'Нет на месте',
    invisible: 'Невидимый',
    outgoing: 'Исходяший',
    incoming: 'Входящий',
    missed: 'Пропущенный',
    deal_by_phone_number: 'Сделка по номеру телефона',
    no_paymnets: 'Нет никаких платежей',
    profit_m: 'Маржа',
    assign_role: 'Назначить роль',
    view: 'Простмотр',
    link: 'Ссылка',
    display_and_wallpaper: 'Дисплей и обои',
    dynamic_wallpaper: 'Динамические обои',
    please_select: 'Пожалуйста выберите',
    message: 'Сообщение',
    more: 'Более',
    accepted: 'Принято',
    not_accepted: ' Не принято',
    about_deal: 'О сделке',
    working: 'Рабочий',
    accounts: 'Счета',
    history: 'История',
    applications: 'Приложения',
    who_gave_the_task: 'Кто дал задание',
    task_in_project: 'Задача в проекте (группе)',
    do_you_really_want_to_do_this: 'Вы действительно хотите это сделать?',
    deal_deleted: 'Сделка удалена',
    warning: 'Предупреждение',
    operation_canceled: 'Операция отменена',
    deal_restored: 'Сделка восстановлена',
    participants: 'Участники',
    no_deadline: 'Нет срока',


    labels: 'Метки',
    deadline: 'Срок',
    restore_to_board: 'Востановить на доску',
    delete_task: 'Удалить задачу',
    unable_to_delete_last_form: 'Невозможно удалить последнюю форму',
    'Enter your email address': 'Введите электронную почту',
    'Please enter your email correctly!': 'Введите электронную почту правильно!',
    'You cannot add more than 5 email addresses!': 'Вы не можете добавить более 5 адресов электронной почты!',
    'Problem checking connection, please try again': 'Проблема при проверке подключения, попробуйте еще раз',
    'To test the connection, first enter your IMAP and SMTP details.': 'Для проверки соединения сначала введите данные IMAP и SMTP.',
    'Are you sure you want to activate the task ?': 'Вы действительно хотите активировать задачу ?',
    'Are you sure you want to activate the deal ?': 'Вы действительно хотите активировать сделку ?',
    completed_tasks: 'Выполненные задачи',
    confirm: 'Подтвердить',
    day: 'день',
    today: 'cегодня',
    call_history: 'История звонков',
    no_audio_file: 'Нет аудиофайла',
    profit_sum: 'Сумма маржи',
    corr_account: 'Корр. счет',
    delete_field: 'Удалить поля',
    add_field: 'Добавить поле',
    contact_person: 'Контактное лицо',
    no_email: 'Нет электронной почты',
    all_deals: 'Всего сделок',
    succesfull: 'Успешные',
    succesfully: 'Успешно',
    fact: 'Факт',
    difference: 'Разница',
    active_d: 'Активные',
    all_tasks: 'Всего задач',
    tasks: 'Задачи',
    client_type: 'Типы клиентов',
    no_task: 'Нет задач',
    additional_client_data: 'Дополнительные данные клиента',
    individual_info: 'Информация о физическом лице',
    January: 'Январь',
    February: 'Февраль',
    March: 'Март',
    April: 'Апрель',
    May: 'Май',
    June: 'Июнь',
    July: 'Июль',
    August: 'Август',
    September: 'Сентябрь',
    October: 'Октябрь',
    November: 'Ноябрь',
    December: 'Декабрь',
    payment_start_date: 'Дата начала оплаты',
    payment_end_date: 'Дата завершения оплаты',
    delete_payment: 'Удалить платеж',
    add_payment: 'Добавить платеж',
    client_with_contact: 'Компании клиентов, связанные с контактом',
    loading: 'Загрузить',
    add_participant: 'Добавить наблюдателя',
    add: 'Добавить',
    'Payment cannot be more than 100%, please correct before saving': 'Оплата не может быть больше 100%. Пожалуйста, исправьте перед сохранением',
    'One of the payments is 0, please enter the percentage for the existing payment first': 'Один из платежей равен 0. Пожалуйста, сначала введите процент для существующего платежа',
    file_limit_warning: 'Можно выбрать только {number} файлов!',
    file_size_limit_warning: 'Загрузите файл размером менее {number} МБ',
    file_already_exists: 'Этот файл уже существует',
    update_deal: 'Изменить сделку',
    'Payment cannot be more than 100%': 'Оплата не может быть больше 100%',
    'Do you want to send a message to the client': 'Вы хотите отправить сообщение клиенту?',
    send: 'Отправить',
    something_wrong_with_connection_of_email: 'Что-то пошло не так при подключении к электронной почте, попробуйте еще раз, чтобы перезагрузить, нажмите кнопку «Эл-письмо» еще раз.',
    error_m: 'Ошибка',
    'There is no email in the transaction to search for messages!': 'В сделке нет электронной почты для поиска сообщений!',
    'Enter one from text or file': 'Введите один из текста или файла',
    'Please select an email address if you would like to send an email': 'Пожалуйста, выберите адрес электронной почты, если хотите отправить письмо',
    all_emails: 'Все электронные почты',
    'Date difference should not be more than 4 days to search all email addresses !': 'Разница в датах не должна быть больше 4 дней для поиска по всем адресам электронной почты!',
    'The date difference should not be more than 10 days for the search!': 'Разница в датах для поиска не должна быть больше 10 дней!',
    no_data_to_search: 'Нет дат для поиска!',
    no_email_to_search_messages: 'В сделке нет электронной почты для поиска сообщений!',
    no_selected_deal_to_save: 'Нет выбранных сделок для сохранения!',
    product_list_is_empty: 'Список продуктов пуст',
    'Are you sure you want to uninstall the product?': 'Вы действительно хотите удалить продукт?',
    deleting_product_canceled: 'Удаление продукта отменено',
    overall: 'Общие',
    archivate_deal: 'Архивировать сделку',
    delete_deal: 'Удалить сделку',
    offer: 'Предложения',
    add_task: 'Добавить задачу',
    kp: 'КП',
    update_payment: 'Изменить оплату',
    do_you_want_to_delete_participant: 'Вы действительно хотите удалить наблюдателя?',
    do_you_want_to_archivate_task: 'Вы действительно хотите архивировать сделку?',
    archivating_completed: 'Архивирование завершено',
    archivating_canceled: 'Архивирование отменено',
    company_y: 'Компания',
    do_you_want_to_delete_board: 'Вы действительно хотите удалить доску?',
    message_m: 'Сообщения',
    activating_deal_canceled: 'Активирование сделки отменено',
    activating_task_canceled: 'Активирование задачи отменено',
    delete_completely: 'Удалить окончательно',
    please_enter_input: 'Пожалуйста, введите {input}',
    length_must_be: 'Длина должна быть {number}',
    length_must_be_min: 'Длина должна быть минимум {number} символов',
    length_must_be_max: 'Длина должна быть максимум {number} символов ',
    'Do you really want to import products from another project': 'Вы действительно хотите импортировать продукты из другого проекта?',
    auto_lock_when_inactive: 'Автоблокировка при бездействии',
    turned_off: 'Выключено',
    numner_of_secunds: '{number} секунд',
    numner_of_minutes: '{number} минут',
    numner_of_hours: '{number} часов',
    auto_lock: 'Автоблокировка',
    passcode: 'Код-пароль',
    enter_your_passcode: 'Введите текущий код-пароль',
    enter_new_password: 'Введите новый код-пароль',
    repeat_passcode: 'Повторите код-пароль',
    enter_your_password_again: 'Пожалуйста, введите пароль еще раз',
    password_does_not_match: 'Пароль не совпадает!',
    number_found_on_server: 'Внутренний номер найден на сервере',
    check_if_number_exists_in_server: 'Проверить, существует ли номер на сервере',
    waiting_to_complete: 'Ждет выполнения',
    delete_participant_from_list: 'Удалить из списка участников',
    completed: 'Выполнено',
    check_task: 'Задание на проверку',
    in_process: 'В процессе',
    task_checked: 'Задача проверена',
    ready_to_check: 'Готов к проверке',
    add_description: 'Добавить описание',
    add_item: 'Добавить элемент',
    downloaded_files: 'Загруженные файлы',
    recommended_settings: ' Настройки рекомендаций',
    add_to_card: 'Добавить на карточку',
    check_list: 'Чек-лист',
    attechment: 'Вложение',
    add_to_completed_tasks: 'Добавить в выполненные задачи',
    operation_completed: 'Операция завершена',
    adding_a_task_list: 'Добавление списка задач',
    dates: 'Даты',
    create_new_label: 'Создать новую метку',
    update_label: 'Изменить метку',
    without_deals: 'Без сделок',
    please_select_input: 'Пожалуйста, выберите {input}',
    user_email: 'Электронная почта пользователя',
    email_password: 'Пароль от электронной почты',
    smtp_server: 'SMTP сервер',
    smtp_port: 'SMTP порт',
    imap_server: 'IMAP сервер',
    imap_port: 'IMAP порт',
    pop3_server: 'POP3 сервер',
    pop3_port: 'POP3 порт',
    add_email: 'Добавить почту',
    deal_c: 'Сделки',
    overdue_tasks: 'Просроченные задачи',
    completed_tasks_for_today: 'Выполненные задачи за сегодня',
    task_to_complete: 'Задачи к выполнению',
    deal_source: 'Источники сделок',
    deals_by_managers: 'Сделки по менеджерам',
    goals: 'Цели',
    deals_by_country: 'Сделки по странам',
    latest_files: 'Последние файлы',
    no_files: 'Нет никаких файлов',
    deal_delete_reason: 'Причины удаления сделок',
    payments_by_period: 'Выплаты по периодам',
    by_days: 'По дням',
    by_months: 'По месяцам',
    by_years: 'По годам',
    selling_product_statistic: 'Статистика по продажам товаров',
    conter_agent: 'Контрагент',
    top_deals: 'Топ 5 успешных сделок ',
    overall: 'Общий',
    total_amount_t: 'Итоговая сумма',
    sale: 'Продажа',
    profit: 'Прибыль',
    no_deal: 'Нет сделок',
    locations: 'Расположения',
    'A file with the same name already exists, choose another one or change the name': 'Файл с таким именем уже существует, измените имя или выберите другой',
    write_text: 'Напишите текст',
    'There is a problem on the server side': 'Есть проблема на стороне сервера',
    information: 'Информация',
    no_phone: 'Нет номера',
    enter_phone_number_to_call: 'Введите номер для звонка !',
    'Are you sure to close this window': 'Вы уверены, что хотите закрыть это окно ?',
    phone_registered_message: 'Телефон: {phone} успешно зарегистрирован !',
    register: 'Зарегистрировать',
    phone_not_registered_message: 'Не удалось зарегистрировать телефон: {phone}, обновите страницу еще раз.',
    wiev_all: 'Посмотреть все',
    cash_box: 'Касса',
    payments: 'Платежи',
    payments_for_expenses: 'Платежи за расходы',
    calls: 'Звонки',
    counterparties: 'Контрагенты',
    h_r: 'HR',
    product_settings: 'Настройки продукта',
    calendar: 'Календарь',
    russia: 'Russia',
    exit: 'Выйти',
    deletion_reason: 'причина удаления',
    text_length_must_be: 'Длина должна быть от {min} до {max}',
    'ANSWERED': "Отвечено",
    'NO ANSWER': "Не отвечено",
    'BUSY': "Занят",
    'settings profile': 'Настройки профиля',
    'settingCompany': 'Настройки компании',
    'logout': 'Выйти',
    'available': 'Доступно',
    'invisable': 'Невидимый',
    'NotDisturb': 'Не беспокоить',
    'busy': 'Занят',
    'absent': 'Нет на месте',
    "Amount without discount and NDS": "Сумма без скидки и налогов",
    "Discount amount": "Сумма скидки",
    "NDS amount": "Сумма налога",
    "Total amount": "Общая сумма",
    prefix: 'Префикс',
    phoneNumberForSipAccountOutsideCalls: 'Телефон для исходящих звонков',
    phoneNumberForSipAccountOutsideCall: 'Телефон для исходящего звонка',
    clients: "Клиенты",
    phone_number_is_not_valid: 'Данный номер телефона неверен. Пожалуйста, введите правильный номер',
    validation: 'Проверка',
    phones: 'Телефоны',
    add_participants: 'Добавить участников',
    call: 'Звонок',
    you_can_not_call_yourself: 'Вы не можете позвонить себе!',
    caller_name: "Имя исходящего",
    target_name: "Имя входящего",
    invalid_phone_alert: "Данный номер телефона недействителен, проверьте учетные данные и повторите попытку!",
    calling: "Вызов",
    home: "Главная",
    goal_in: "Цель в",
    task_map: "Карта задач",
    please_enter_phone_number: "Пожалуйста, введите телефон",
    phone_already_exits: "Данный номер телефона уже существует, введите другой",
    unregister: "Отменить регистрацию",
    registered: "Зарегистрирован",
    unregistered: "Отключен",
    phone_not_registered_please_register_phone_to_make_a_call: 'Данный номер телефона не зарегистрирован. Пожалуйста, зарегистрируйте телефон чтобы позвонить',
    cash_register: 'Касса',
    payments: 'Платежи',
    payment: 'Платеж',
    bankAccounts: 'Банковские счета',
    bankAccount: 'Банковский счет',
    paymentType: "Тип оплаты",
    unpaidClientDeals: "Неоплаченные сделки клиента",
    pay_for_deals: "Оплатить сделки",
    paid_money: "Оплачено",
    remainder: "Остаток",
    insufficient_money: "Недостаточно денег. Пожалуйста, проверьте еще раз!",

    // not translated to turkish

    paying_money: "Текущий платеж",
    client_balance: "Баланс клиента",
    remainder_for_client_balance: "Остаток для клиентского баланса",
    enter_money_correctly: "Введите сумму правильно",
    payment_from_client_balance: "Оплата с баланса клиента",
    no_payments_to_deals: "Нет выплат по сделкам",
    payemnts_for_costs: 'Платежи за расходы',
    outgoing_cost: 'Исходящий платеж',
    outgoing_costs: 'Исходящие платежи',
    outgoing_money: 'Исходящая сумма',
    measurement_short: "Изм",
    calculate_money: "Рассчитать сумму по",
    service: "Услуга",
    services: "Услуги",
    paid_money_will_be_changed_are_you_sure: "Уплаченная сумма за сделку изменятся, вы хотите обновить?",
    exhibition: 'Выставка',
    exhibitions: 'Выставки',
    exhibitionType: 'Тип выставки',
    exhibitionTypes: 'Типы выставок',
    expenses: 'Затраты',
    to: 'До',
    remaining: 'Осталось',
    managers: 'Менеджеры',
    add_managers: 'Добавить менеджеров',
    happyCall: 'Счастливый звонок',
    happyCalls: 'Счастливые звонки',
    happyCallQuestion: 'Вопрос по телефону',
    happyCallQuestions: 'Вопросы по телефону',
    happyCallAnswer: 'Счастливый ответ',
    happyCallAnswers: 'Счастливые ответы',
    questions: 'Вопросы',
    archived: 'Архивированный',
    rating: 'Балл',
    childProducts: 'Товары',
    waste: 'Угар',
    auto_calculate: 'Авто расчет',
    auto: 'Авто',
    manual: 'Ручной',
    excel: 'Excel',
    set_cover: 'Прикрепить',
    remove_cover: 'Откреплять',
    attachments: 'Вложения',
    new_list: 'Новый список задач',
    others: 'Другие',
    please_input: 'Пожалуйста, введите',
    image: "Изображение",
    provider: "Поставщик",
    provider_price_list: "Прайс-лист поставщика",
    service_provider: 'Услуги поставщика',
    'services do not have': 'Услуг пока нет',
    notificationTypes: 'Типы уведомлений',
    notificationType: 'Тип уведомления',
    all_users: 'Все пользователи',
    notification: 'Системное уведомление',
    notifications: 'Системные уведомления',
    activity_time: 'Время активности',
    review: 'Обзор',
    reviewed: 'Рассмотрено',
    not_reviewed: 'Не рассмотрено',
    reviewed_date: 'Дата проверки',
    notifications_nn: 'Уведомления',
    all_notifications: 'Все уведомления',
    queues: 'В очереди',
    days: 'дней',
    typeOfProjectWorks: 'Тип проектной работы',
    projects: 'Проекты',
    project: 'Проект',
    project_name: 'Название проекта',
    'project without a deadline': 'Проект без срока',
    term: 'Срок',
    start: 'Старт',
    finish: 'Финиш',
    plan: 'План',
    hours: 'Часов',
    project_manager: 'Руководитель проекта',
    type_of_project_work: 'Тип проектной работы',
    next: 'Следующий',
    prev: 'Предыдущий',
    notifationTypes: 'Типы уведомлений',
    new_y: 'Новые',
    for_checking: 'На проверке',
    completed_y: 'Выполненные',
    progress: 'Прогресс',
    no_tasks: 'Нет задач',
    step_number: 'Шаг {number}',
    project_color: 'Цвет проекта',
    created: 'Создан',
    overdue: 'Просроченный',
    meeting_time: 'Время встречи',
    in_an_hour: 'за час',
    in_two_hours: 'за два',
    in_three_hours: 'за три',
    in_four_hours: 'за четыре',
    per_day: 'за день',
    creator: 'Создатель',
    managerTypes: 'Типы менеджеров',
    bonuses_manager_type: 'Бонусы менеджерского типа',
    'bonuses of manager type for first deal': 'Бонусы менеджерского типа за первую сделку',
    profit_user: 'Прибыль пользователя',
    content: 'Содержание',
    set_product_price: "Установить цену продукта",
    client_name: 'Имя клиента',
    individual: 'Физическое лицо',
    legal: 'Юридическое лицо',
    required: 'Обязательно',
    not_required: 'Не обязательно',
    production: 'Производство',
    productionable: 'Производственный',
    ready: 'Готовый',
    product_type: 'Тип продукта',
    additional_fee_of_product: 'Дополнительная плата за продукт',
    percentage: 'Процент (%)',
    calculate_by: 'Рассчитать по',
    calculation_by: 'Рассчитать по',
    calculations: 'Расчеты',
    additional_fees: 'Дополнительная плата',
    upkeep: 'Содержание',
    unit_price: 'Цена за единицу',
    price_net: 'Цена NET',
    custom_form: 'Пользовательская форма',
    product_composition: 'Состав продукта',
    composition_should_be_100: 'Общий состав продукта должен быть 100%',
    invalid_percentage: 'Недопустимый процент состава продукта. Процент продукта должно быть больше 0% и не может быть больше 100%.',
    social: 'Социальный',
    for_unit: 'За единицу',
    price_for_unit: 'Цена за единицу',
    price_for_all: 'Цена за все',
    inactive_clients: "Неактивные клиенты",
    inactive_for: 'Не активен {number} месяц',
    files_of_deal_conditions: 'Файлы условий оплаты',
    custom_form_is_not_be_empty: 'Пользовательская форма не может быть пустой',
    product_composition: 'Состав продукта',
    composition_should_be_100: 'Общий состав продукта должен быть 100%',
    invalid_percentage: 'Недопустимый процент состава продукта. Процент продукта должно быть больше 0% и не может быть больше 100%.',
    social: 'Социальный',
    price_for_unit: 'Цена за единицу',
    price_for_all: 'Цена за все',
    'The file must be JPG, PNG and PDF format': 'Файл должен быть в формате JPG, PNG и PDF',
    copyURL: 'Скопировать URL',
    linkCopied: 'Ссылка скопирована',
    files_of_client_form: 'Файлы клиентской формы',
    answers_of_clients: 'Ответы клиентов',
    managers_expected_profit: 'Ожидаемая прибыль менеджеров',
    paid_to_managers: 'Выплачивается менеджерам',
    'Верхний текст': 'Верхний колонтитул',
    'Нижний текст': 'Нижний колонтитул',
    'Содержание': 'Содержание',
    'label': 'Название',
    'Shipping address': 'Новое поле',
    'Rule': 'Обязательно для заполнения',
    'Required': 'Обязательно для заполнения',
    'Type': 'Тип',
    'Input': 'Ввод',
    'InputNumber': 'Ввод числа',
    'Select': 'Варианты',
    'Radio': 'Единичный выбор',
    'Checkbox': 'Множественный выбор',
    'Switch': 'Переключатель',
    'DatePicker': 'Дата',
    'addNewOption': 'Добавить вариант',
    'simpleForm': 'Простая форма',
    'dealForm': 'Форма со сделкой',
    Upload: 'Загрузить',
    create_component: 'Создать компонент',
    meeting: 'Встречи',
    all: 'Все',
    search_by_product_and_category: "Продукт: поиск продукта после ' , ' поиск категории",
    calculation_types: 'Типы расчета',
    calculation_type: 'Тип расчета',
    service_sum_for_unit: 'Цена Услуги',
    ru: 'Русский',
    uz: 'Узбекский',
    en: 'Английский',
    tr: 'Турецкий',
    save_as_default: 'Сохранить содержание продукта',
    product_already_has_recipe_wanna_continue: 'Продукт уже имеет содержание, вы действительно хотите изменить содержание продукта!',
    workMeetingType: 'Тип собрание',
    workMeetingTypes: 'Тип собрание',
    workMeeting: 'Собрания',
    workMeetings: 'Собрания',
    organize: 'Организовать',
    meeting_goal: 'Цель собрание',
    upload_file: 'Загрузить файл',
    detail: 'Подробность',
    workMeetingInfo: "Подробности собрание",
    create_task: "Создать задачу",
    file_name: "Имя файла",
    drag_upload_text: "Чтобы загрузить файл перетащите или щелкните в области",
    changeColor: 'Изменить цвет',
    print: 'Распечатать',
    indicator_by_sums: 'Показатель по суммам',
    indicator_by_quantities: 'Товарооборот по количеству',
    payment_status: 'Статус оплаты',
    bad: 'Плохо',
    avarage_volume: 'Средний объем',
    meetingType: 'Тип встречи',
    meetingTypes: 'Тип встречи',
	meetings: 'Поездка',
	samples_for_messages: 'Образцы для сообщений',
	by_meetings: 'По поездкам',
	archivate: 'Архивировать',
	vendor_code: 'Артикуль',
	meeting_format: 'Формат встречи',
	online: 'Онлайн',
	offline: 'Офлайн',
	departments: 'Отделы',
	periodicity: 'Периодичность',
	end_date_of_periodicity: 'Дата окончания периодичности',
	import_products: 'Импортировать продукты',
	'Enable two-step authentication': 'Включить двухэтапную аутентификацию',
	'Turn on': 'Включить',
	protocol: 'Протокол',
	create_deal : 'Создать сделку',
	by_amount: 'по сумму',
    script: 'script',
    button: 'Кнопка',
	reason_of_refus: 'Причина отказа',
	corrected: 'Поправлена',
    widget: 'Виджет',
    your_request_sent_successfully: 'Ваш запрос успешно отправлен! Спасибо за внимание.',
	conclusion: 'Заключение',
	managerReport: 'Отчёт по менеджером',
	recall: 'Отзыв',
	visit_and_meetings: 'Визит и Встречи',
	debt: 'Задолженность',
	client_additional: 'Дополнительная информация о клиентах',
    column_name: 'Наименование',
    column_type: 'Тип ввода',
    options: 'Варианты',
    add_additional_info: 'Добавить дополнительную информацию',
    choose_additional_info: 'Выберите дополнительную информацию, которую вам удалось получить',
    fill_additional_info: 'Заполните дополнительную информацию',
    clientAdditionalInfo: 'Дополнительная собранная информация о клиенте',
    client_not_recognized: 'Клиент не распознан',
    "active-text": 'Текст для принятия',
    "inactive-text": 'Текст для отклонения',
    Textarea: 'Текстовая область',
    price_list: "Прайс-лист",
    price_lists: "Прайс-листы",
    servicePriceList: "Прайс-лист на услуги",
    inspectors: 'Менеджер для подтверждения',
    approved: 'Подтвержден',
    send_to_approve: 'Отправить на подтверждение',
    approve: 'Подтверждать',
    waiting_confirmation: 'Ожидает подтверждения',
    marketplace: 'Маркетплейс',
    url_address: 'URL-адрес',
    login_name: 'Логин',
    messengers: 'Мессенджеры',
    telegram: 'Телеграм',
    chat_id: 'CHAT ID',
    conversation_status: 'Статус разговора',
    'Service Unavailable': 'Внутренний номер не недоступен!',
    'Call counts by Managers': 'Количество звонков по менеджерам',
    'Call counts by Messengers': 'Количество звонков по мессенджерам',
    main: 'Главная',
    voice: 'Голосовой',
    audio: 'Аудио',
    document: 'Документ',
    video: 'Видео',
    photo: 'Изображение',
    success: 'Успешно',
    sms: 'SMS сообщение',
    sending_type: 'Тип отправки',
    message_receivers: 'Получатели сообщений',
    selected: 'Выбранные',
    sending_date: 'Дата отправки',
    sendOrSaveMessage: 'Отправить или сохранить сообщение',
    phone_numbers: 'Телефонные номера',
    add_phone_number: 'Добавьте номер телефона',
    invalid_phones_are_detected_check_again: "Обнаружены неверные номера телефонов, пожалуйста, проверьте еще раз. \n {numbers}",
    there_is_no_phones: 'Номеров телефонов нет, пожалуйста, введите номера телефонов, чтобы отправить сообщение',

    sms_recieving_clients_count: 'Количество клиентов, получающих смс',
    sms_recieving_users_count: 'Количество менеджеров по приему смс',
    sms_recieving_phones_count: 'Количество телефонов для приема смс',
    total_message_sms_receivers : 'Общее количество получателей SMS-сообщений',
    answered: "Отвечено",
    no_answer: "Не отвечено",
    download_excel: "Скачать Excel",
    telegramBotSetting: 'Настройка телеграмм-бота',
    telegramBots: 'Телеграмм-бот',
    token: 'Токен',
    attachToClient: 'Прикрепить к клиенту',
    client_not_attached_to_chat: 'Клиент не привязан к этому чату',
    numbers_attached_to_users_will_be_removed: 'Вы действительно хотите это сделать? Номера, прикрепленные к пользователям, будут удалены.',
    detach_user_numbers: 'Отсоединить номера пользователей',
    FAILED: 'Неуспешный',
    unknown_number: 'Неизвестный номер',
    incoming_call: "Входящий звонок",
    call_client:  "Позвонить клиенту",
    last_call: 'Последний звонок',
    new_number_detected: "Новый номер телефона, история звонков по этому номеру не найдена в системе.",
    searching_caller_history: 'В процессе поиска истории звонков ...',
    phone_registered_on_another_device: 'Sip-номер уже зарегистрирован на другом устройстве, пожалуйста, отключите другое устройство для регистрации или попробуйте позже.',
    phone_unregistered: 'SIP-номер: {phone} успешно отключен, чтобы зарегистрироваться еще раз, пожалуйста, измените статус на активный или обновите страницу.',
    diconnect: 'Отключить',
    disconnected: 'Отключен',
    client_show: 'client_show',
    product_catalog: 'Каталог товаров',
    network_error: 'Ошибка сети',
    network_connection_error: 'Пожалуйста, проверьте подключение к интернету.',
    parent_product_not_found: 'Основной товар не найден !',
    kpi_panel: 'KPI-панель эффективности продаж',
    sale_report: 'Отчет о продажах',
    fact_panel: 'План-факт анализ Бюджета доходов и расходов (БДР)',
    sales: 'Продажи',
    avarage_price: 'Средняя цена (ARPU)',
    profitability: 'Рентабельность',
    per_volume: 'На объем',
    deviations: 'Отклонения',
    grade: 'Оценка',
    diagram: 'Диаграмма',
    break_even_point: 'Точка безубыточность',
    factAnalysisPerformanceByProfit: 'Факторный анализ выполнения плана по прибыли',
    keyIndicators: 'Ключевые показатели',
    sellingPlanPerformanceByCountry: 'Выполнение плана продаж по регионам',
    sellingPlanPerformanceByYear: 'Выполнение плана продаж за год',
    analysisImpactVolume: 'Анализ влияния объемов, цен и расходов на прибыль',
    top_ten_managers: 'Первая десятка менеджеров',
    productsRanking: 'Ранжирование продукции и товаров',
    performance: 'Выполнение',
    chart: 'Диаграмма',
    revenue: 'Выручка',
    volume: 'Объем',
    average: 'Средний',

    profit_d: 'Марж доход',
    profit_percentage: 'Марж д. %',
    sold_units: 'Продано единиц',
    average_deal_money_amount: 'Сред цена сделки',
    volume_rank: 'Ранг объема',
    download_products_file: 'Скачать PDF-файл продукции',
    download_knit: 'Скачать вязания',
    client_price: 'Цена клиента',
    profit_changed_from_to_percent: "Прибыль обновлена с {from}% до {to}%",
    min_profit_value_warning: "Минимальная прибыль от этого продукта должна составлять {value}%",
    new_price_set_from_to: "Новая цена установлена от {from}{currency} до {to}{currency}",
    microphone_disabled: 'Микрофон отключен',
    enable_your_microphone: 'Пожалуйста, включите или подключите микрофон',
    microphone_volume_is_disabled: 'Громкость микрофона отключена',
    microphone_success: 'Состояние микрофона положительное',
    greeting_message: 'Приветственное сообщение'
}
